import React from "react"
interface LoadingPreviewProps{
    loading:boolean,
    message:string
}
const LoadingPreview=({loading,message}:LoadingPreviewProps)=>{
    
    return loading?(
        <div id="cover-spin" >
            <h3>{message}</h3>
        </div>
    ):<></>
}

export default LoadingPreview