export enum TableCode {
    Organization = 101,
    Driver = 104,
    Student = 105,
    Guardian = 106,
    User = 107,
    Route = 108,
    Asset = 109,
    Device = 110,
    StudentGuardian = 112,
    StudentStop = 111,
    Sensor = 114
}

export enum OrganizationType {
    SuperAdmin = 1,
    Company = 2,
    CompanyVendor = 3,
}