import React from 'react';
import {Spinner} from "reactstrap";

interface IProps {
    show: boolean
}

function PageLoading(props: IProps) {
    if (!props.show) {
        return <></>;
    }

    return (
        <div className="page-loader">
            <div className="loading-content">
                <Spinner color="primary"/>
            </div>
        </div>
    );
}

PageLoading.defaultProps = {
    show: false
};

export default PageLoading;
