export interface IUiState {
    isSideNavShrinked: boolean,
    showPageLoading: boolean,
    totalUnReadNotification: number,

    alertNotifications:any,
    currentSideNavCollapse:string,
}

export enum IUiConstants {
    TOGGLE_SIDE_NAV_SHRINK = 'TOGGLE_SIDE_NAV_SHRINK',
    SHOW_PAGE_LOADING = 'SHOW_PAGE_LOADING',
    HIDE_PAGE_LOADING = 'HIDE_PAGE_LOADING',
    TOTAL_UNREAD_NOTIFICATION = 'TOTAL_UNREAD_NOTIFICATION',
    SET_SIDE_NAV_SHRINK = "SET_SIDE_NAVE_SHRINK",
    SET_SIDENAV_COLLAPSE = "REPLACE_SIDENAV_COLLAPSE",

    SET_ALERT_NOTIFICATION = "REPLACE_ALERT_NOTIFICATION",
}

const init: IUiState = {
    isSideNavShrinked: false,
    showPageLoading: false,
    totalUnReadNotification: 0,
    alertNotifications:[],
    currentSideNavCollapse:'',
};

export function UiReducer(state: IUiState = init, action: any): IUiState {
    switch (action.type) {
        case IUiConstants.TOGGLE_SIDE_NAV_SHRINK:
            const isSideNavShrinked = !state.isSideNavShrinked;
            return {...state, isSideNavShrinked};
        case IUiConstants.SET_SIDE_NAV_SHRINK:
            return {...state, isSideNavShrinked : action.value};
        case IUiConstants.SHOW_PAGE_LOADING:
            return {...state, showPageLoading : true};
        case IUiConstants.HIDE_PAGE_LOADING:
            return {...state, showPageLoading : false};
        case IUiConstants.TOTAL_UNREAD_NOTIFICATION:
            return {...state, totalUnReadNotification : action.value};
        case IUiConstants.SET_ALERT_NOTIFICATION:
            return {...state, alertNotifications : action.value};
        case IUiConstants.SET_SIDENAV_COLLAPSE:
            return {...state, currentSideNavCollapse : action.value};
        default:
            return state;
    }
}

export class UiAction {
    static toggleSideNavShrink = () => {
        return {type: IUiConstants.TOGGLE_SIDE_NAV_SHRINK};
    };

    static setSideNavShrink = (value: boolean) => {
        return {type: IUiConstants.SET_SIDE_NAV_SHRINK, value: value};
    };

    static showPageLoading = () => {
        return {type: IUiConstants.SHOW_PAGE_LOADING};
    };

    static hidePageLoading = () => {
        return {type: IUiConstants.HIDE_PAGE_LOADING};
    };

    static setTotalUnReadNotification = (value: number) => {
        return {type: IUiConstants.TOTAL_UNREAD_NOTIFICATION, value: value};
    };

    static setAlertNotifications = (data:any) => {
        return {type: IUiConstants.SET_ALERT_NOTIFICATION,value:data};
    };

    static setSideNavCollapse = (data:any) => {
        return {type: IUiConstants.SET_SIDENAV_COLLAPSE,value:data};
    };
}
